import { Close } from '@mui/icons-material';
import { Dialog, Divider, IconButton } from '@mui/material';
import React from 'react';

const ModalLayout = ({ open, closeAction, title, description, children, setCrossButton = "true" }) => {
    const dialogStyle = {
        width: '100%',
        flexShrink: 0,
    }
    const dialogHeader = {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
        zIndex: 100,
    }
    const dialogContent = {
        flexGrow: 1,
    }

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason && reason !== "backdropClick") {
                    closeAction();
                }
            }}
            disableEscapeKeyDown
            fullWidth
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={dialogStyle}
        >
            <div style={dialogHeader}>
                <div className="d-flex justfy-content-between align-items-center py-3 px-4">
                    <div className="flex-grow-1">
                        <h3 className="text-purple fs-x-large-semibold p-0 m-0">
                            {title}
                        </h3>
                        {description ? <p className="text-muted fs-normal-regular p-0 m-0">{description}</p> : ''}
                    </div>
                    {setCrossButton === "true" ? (
                        <IconButton color="secondary" onClick={() => closeAction()}>
                            <Close fontSize="medium" />
                        </IconButton>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <Divider />
            <div style={dialogContent}>
                <div className="py-3 px-4">{children}</div>
            </div>
        </Dialog>
    );
}

export default ModalLayout;