import axios from "axios"
import { getUserAuthToken, getUserRefreshToken, setUserAuth, setUserAuthToken, setUserRefreshToken ,setUserTransactionToken} from "../../utils/LocalStorageUtils";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const OPEN_URL_LIST = [
    '/get-otp',
    '/verify-otp',
    '/token-auth',
    '/refresh-token',
    '/investor-sign-up',
    '/email-login-init'
]

const MULTI_PART_LIST = [
    '/upload-image',
    '/upload-document'
]

const AUTHENTICATE = '/get-otp'
const OTP_AUTH = '/verify-otp'
const OTP_VERIFY_EMAIL = '/verify-email'
const REFRESH_TOKEN = '/refresh-token'
const TOKEN_AUTH = '/token-auth'
const SILENT_LOGIN = '/silent-login'
const RESEND_OTP_API = '/resend-otp'
const Investor_Signu_API = '/investor-sign-up'
const EMAIL_LOGIN_INIT = '/email-login-init'
const Email_Login_GetOtp = '/email-login-get-otp'
const SWITCH_ACCOUNT='/webapp/switch-account'

let isRefreshing = false; // Track whether a token refresh is in progress
let refreshSubscribers = []; // Array to hold the pending API requests

const handleServerError = (error) => {

    if( error && error.response && error.response.data && error.response.data.description){
        return error.response.data.description
    }

    if(error.message){
        return error.message
    }

    return "Something went wrong"
}

const createRequestInterceptor = () => {

    axios.interceptors.request.use(config => {

        let token = getUserAuthToken()

        // open url list will be excluded
        if (!OPEN_URL_LIST.includes(config.url.replace(BASE_URL, '')) && token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        if(!MULTI_PART_LIST.includes(config.url.replace(BASE_URL, ''))){

            config.headers['Content-Type'] = 'application/json';

        } else {

            config.headers['Content-Type'] = 'multipart/form-data'

        }

        return config
    }, error => {
        return Promise.reject(error)
    })
}

const createResponseInterceptor = () => {


    const interceptor = axios.interceptors.response.use(({ config, data }) => {


    if ([OTP_AUTH, REFRESH_TOKEN, TOKEN_AUTH, SILENT_LOGIN, RESEND_OTP_API, Investor_Signu_API, EMAIL_LOGIN_INIT, Email_Login_GetOtp, OTP_VERIFY_EMAIL,SWITCH_ACCOUNT].includes(config.url.replace(BASE_URL, ''))) {

        /*
           -> save token and user data in local storage
           -> delete token from data object
        */

        //TODO : Change below code according to API response
        if (data.status && data.data.jwt && data.data.refreshToken ) {
            setUserAuthToken(data.data.jwt)
            setUserRefreshToken(data.data.refreshToken)
            setUserAuth(true)

            if(data.data.transactionToken){
                setUserTransactionToken(data.data.transactionToken)
                delete data.data.transactionToken
            }
            
            delete data.data.jwt
            delete data.data.refreshToken

        }
        if (data.status && data.data.transactionToken ) {
            setUserTransactionToken(data.data.transactionToken)
            delete data.data.transactionToken
        }
        if (data.status &&  data.data?.auth?.transactionToken) {
            setUserTransactionToken(data.data?.auth?.transactionToken)
            delete data.data?.auth?.transactionToken
        }

    }

    return Promise.resolve(data)

}, async e =>  {

    const originalRequest = e.config;
    const reqUrl = e.response.config.url.replace(BASE_URL, '');

    if (e?.response?.status === 401 && !originalRequest._retry && reqUrl !== REFRESH_TOKEN) {
        originalRequest._retry = true;

        const retryOriginalRequest = new Promise((resolve) => {
            refreshSubscribers.push(() => resolve(axios(originalRequest)))
        });

        if (!isRefreshing) {
            isRefreshing = true

            try {

                const body = {
                    refreshToken: getUserRefreshToken()
                }

                // removeUserAuthToken()

                 await axios
                    .post(
                        `${BASE_URL}${REFRESH_TOKEN}`,
                        body,
                    )

                refreshSubscribers.forEach((subscriber) => subscriber())
                refreshSubscribers = []

            } catch (refreshError) {
                localStorage.clear();
                window.location = "/";
            }

            isRefreshing = false
        }

        return retryOriginalRequest
    }

    return Promise.reject(handleServerError(e))
})
}

createRequestInterceptor()
createResponseInterceptor()