import React, { createContext, useContext, useReducer, useEffect } from 'react';

const initialState = {
  mcaSkipped: false,
};
const UserContext = createContext();
const SET_MCA_SKIPPED = 'SET_MCA_SKIPPED';
const RESET_USER_STATE = 'RESET_USER_STATE';
const userReducer = (state, action) => {
  switch (action.type) {
    case SET_MCA_SKIPPED:
      return {
        ...state,
        mcaSkipped: action.payload
      };
    case RESET_USER_STATE:
      return initialState;
    default:
      return state;
  }
};
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const setMcaSkipped = (value) => {
    dispatch({ type: SET_MCA_SKIPPED, payload: value });
  };
  const resetUserState = () => {
    dispatch({ type: RESET_USER_STATE });
  };

  useEffect(() => {
    const savedState = sessionStorage.getItem('userState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      if (parsedState.mcaSkipped) {
        dispatch({ type: SET_MCA_SKIPPED, payload: parsedState.mcaSkipped });
      }
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('userState', JSON.stringify(state));
  }, [state]);

  const value = {
    userState: state,
    setMcaSkipped,
    resetUserState
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};