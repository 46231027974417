import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import parse from "html-react-parser";
import Stack from "@mui/material/Stack";
import { BASE_URL } from "../../../utils/constants/constants";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Divider, Typography, Box, Link } from "@mui/material";
import ModalLayout from '../../components/layout/ModalLayout';
import { getVoteDetailsApi, getVoteListingApi, apiSubmitVote } from "../../apis/apis";

const VotingDrawer = ({ open, closeAction, selectedVoteId,setCrossButton="true" }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [error,setError]= useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [voteDetailsList, setVoteDetailsList] = useState(null);
    const [selectedVoteDetails, setSelectedVoteDetails] = useState(null);
    const [status, setStatus] = useState("");

    useEffect(() => {
        if (selectedVoteId) {
            getVoteDetailsApi(selectedVoteId)
                .then((res) => {
                    setVoteDetailsList(res);
                })
                .catch((err) => {
                    console.error("Error fetching vote details:", err);
                });

            getVoteListingApi(selectedVoteId)
                .then((res) => {
                    setSelectedVoteDetails(res);
                    if (res?.voteDetails?.isClosed === "1" || res?.voteDetails?.isClosed === 1) {
                        setStatus("Closed");
                    } else if (res?.voteDetails?.voteStatus === "1" || res?.voteDetails?.voteStatus === 1) {
                        setStatus("Casted");
                    } else {
                        setStatus("Pending");
                    }

                    if (res?.voteDetails?.voRid && res?.votePercentageDetails?.length > 0) {
                        const matchedOption = res.votePercentageDetails.find(
                            (option) => Number(option.voRid) === Number(res.voteDetails.voRid)
                        );

                        if (matchedOption) {
                            setSelectedOption(matchedOption.voRid);
                        } else {
                            console.warn("No matching option found");
                        }
                    }
                })
                .catch((err) => {
                    console.error("Error fetching votes:", err);
                });
        }
    }, [selectedVoteId]);


    const options = Object.keys(selectedVoteDetails?.PercentageOfVotes || {});
    const longestOptionWidth = Math.max(...options.map(option => option.length)) * 9;

    const handleSubmitVote = (selectedVoteDetails) => {
        if (selectedOption === null) {
            setError("Please vote for any one option.");
            return;
        }
        const reqParam = {
            voteRid: selectedVoteDetails.voteDetails.voteRid,
            voRid: selectedOption
        };
        apiSubmitVote(reqParam)
            .then((res) => {
                enqueueSnackbar("Investor voting saved successfully!", { variant: "success" });
                handleClose();
            }
            )
            .catch((err) => {
                console.error("Error fetching votes:", err);
                handleClose();
            });
    };

    const handleClose=()=>{
        closeAction();
        setSelectedOption(null);
        setError("");
    }

    const maxLabelLength = Math.max(...(selectedVoteDetails?.votePercentageDetails?.map(({ label }) => label.length) || [0]));

    const labelWidth = `${maxLabelLength * 8.3}px`;
    

    useEffect(() => {
        if (selectedOption !== null) {
            setError(""); 
        }
    }, [selectedOption]); 

    return (
        <ModalLayout
            open={open}
            title={
                status === "Pending"
                    ? "Cast Your Vote"
                    : status === "Casted"
                        ? "Update Your Vote"
                        : "Voting Details"
            }
            description=""
            closeAction={handleClose}
            setCrossButton={setCrossButton}
        >
            <Typography className="fs-med-semibold text-secondary" style={{ flex: 1 }}>
                <strong>Subject : </strong> {selectedVoteDetails?.voteDetails?.subject}
            </Typography>
            <Typography className="fs-med-semibold text-secondary" style={{ flex: 1, paddingTop: 10 }}>
                {parse(selectedVoteDetails?.voteDetails?.body || "", {
                    replace: (domNode) => {
                        if (domNode.name === "img") {
                            return (
                                <img
                                    src={domNode.attribs.src}
                                    alt={domNode.attribs.alt || "Image"}
                                    style={{ maxWidth: "100%", height: "auto" }}
                                />
                            );
                        }
                    }
                })}
            </Typography>
            {selectedVoteDetails?.voteDetails?.attachment?.length > 0 && (
                <Box sx={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                    <p className="fs-med-medium" style={{ marginBottom: "0rem" }}>Documents:</p>
                    {selectedVoteDetails?.voteDetails?.attachment
                        .filter(Boolean)
                        .map((data, key) =>
                            data?.actualFileName ? (
                                <Stack title={data.actualFileName} key={key} style={{ flexDirection: "column", display: "flex" }}>
                                    <>
                                        <Link
                                            color="secondary"
                                            target="_blank"
                                            href={`${BASE_URL}/${data.path}`}
                                            sx={{ marginLeft: "5px" }}
                                        >
                                            <AttachFileIcon
                                                color="secondary"
                                                style={{ cursor: "pointer" }}
                                            />
                                        </Link>
                                    </>
                                </Stack>
                            ) : null
                        )
                    }
                </Box>
            )}
            <Divider style={{ margin: "10px 0" }} />

            {selectedVoteDetails?.voteDetails?.isClosed !== 1 ? (
                <div>
                    {voteDetailsList?.voteOptions?.map((option, index) => (
                        <div key={index} style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center", marginBottom: "10px" }}>
                            <input
                                type="radio"
                                name="voting"
                                value={option}
                                checked={selectedOption === option.voRid}
                                onChange={() => setSelectedOption(option.voRid)}
                            />
                            <Typography style={{ marginBottom: "0rem", flex: 1 }} className="fs-med-semibold text-secondary">{option.label}</Typography>
                        </div>
                    ))}
                </div>
            ) : (
                <Box sx={{ marginTop: "10px", display: "flex", flexDirection: "column" }}>
                    {Object.entries(selectedVoteDetails?.votePercentageDetails).map(([option, percentage]) => (
                        <Box
                            key={option}
                            sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
                        >
                            {percentage.label === "Pending" ? (
                                <div style={{ marginLeft: "30px" }} />
                            ) : (
                                <input
                                    type="radio"
                                    name="voting"
                                    value={option}
                                    checked={selectedOption === percentage.voRid}
                                    style={{ marginRight: "15px" }}
                                />)}
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    width: labelWidth,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                  }}
                                className="fs-med-semibold text-secondary"
                            >
                                {percentage.label}
                            </Typography>
                            <Typography sx={{ margin: "0 10px" }} className="fs-med-semibold text-secondary">:</Typography>
                            <Typography className="fs-med-semibold text-secondary"style={{flex:1}}>{percentage.votePercentage>0?percentage.votePercentage:0}%</Typography>
                        </Box>
                    ))}
                    <p style={{ color: "red", marginTop: "10px" }} className="fs-med-semibold">This vote is already closed</p>
                </Box>
            )}
         <p style={{ color: "red",margin:0 }} className="fs-med-semibold">{error}</p>
            {selectedVoteDetails?.voteDetails.isClosed === 1 ? (
                <div />
            ) : (
                <Button variant="contained" color="primary" sx={{ color: "white", marginTop: "10px" }} onClick={() => handleSubmitVote(selectedVoteDetails)}>
                    {(selectedVoteDetails?.voteDetails?.voteStatus === "0" || selectedVoteDetails?.voteDetails?.voteStatus === 0)
                        ? "Submit"
                        : "Modify"}
                </Button>
            )}
        </ModalLayout>
    );
};

export default VotingDrawer;
