import { useEffect, useState } from "react";
import { Card, CardContent, Typography, Stack } from "@mui/material";
import VotingDrawer from "./VotingDrawer";
import { getVoteListApi } from "../../apis/apis";
import {
    getVoteDetailLocalStorage, setVoteDetailLocalStorage,
    setVoteOptionLocalStorage
} from "../../../utils/LocalStorageUtils";

function VotingPage() {
    const [open, setOpen] = useState(false);
    const [voteList, setVoteList] = useState([]);
    const [selectedVoteId, setSelectedVoteId] = useState(null);

    const handleViewClick = (data) => {
        setSelectedVoteId(data.voteRid);
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
        setSelectedVoteId(null);
        getVoteList();
        const storedVote = getVoteDetailLocalStorage();
        if (storedVote?.voteRid) {
            setVoteDetailLocalStorage(null);
            setVoteOptionLocalStorage(null);
            window.location.reload();
        }
    };

    const getVoteList = () => {
        getVoteListApi()
            .then((res) => {
                setVoteList(res.voteList);
            })
            .catch((err) => {
                console.error("Error fetching votes:", err);
            });
    };

    useEffect(() => {
        const storedVote = getVoteDetailLocalStorage();
        if (storedVote?.voteRid) {
            setSelectedVoteId(storedVote.voteRid);
            setOpen(true);
        } else {
            getVoteList();
        }
    }, []);

    return (
        <Stack spacing={2} sx={{ marginTop: "2rem", marginX: "2rem" }}>
            {voteList?.map((data) => (
                <Card key={data.voteRid} sx={{ backgroundColor: "white" }}>
                    <CardContent sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography className="fs-med-semibold text-secondary" sx={{ flex: 1 }}>
                            {data.subject}
                        </Typography>
                        <Typography
                            className="fs-med-semibold"
                            sx={{
                                flex: 0.1,
                                color: data.isClosed === 1 
                                    ? "#5f58ff"
                                    : data.voteStatus === 0
                                        ? "#ffa500"
                                        : "green"
                            }}
                        >
                            {data.isClosed === 1  
                                ? "Closed"
                                : data.voteStatus === 0
                                    ? "Pending"
                                    : "Voted"}
                        </Typography>
                        <Typography
                            className="fs-med-regular text-primary"
                            sx={{ cursor: "pointer", textDecoration: "underline", flex: 0.1 }}
                            onClick={() => handleViewClick(data)}
                        >
                            View
                        </Typography>
                    </CardContent>
                </Card>
            ))}
            <VotingDrawer open={open} closeAction={handleClose} selectedVoteId={selectedVoteId} />
        </Stack>
    );
}

export default VotingPage;
