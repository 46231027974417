// export const BASE_URL = "https://tomcat.uatdemo.info/sprintvc"

export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const DD_TYPE_PROJECT_DOMAIN = 1;

export const DD_TYPE_INVESTMENT_TYPE = 2;

export const DD_TYPE_STAGE_OF_COMPANY = 3;

export const DD_TYPE_INV_ROUND_TYPE = 4;

export const DD_TYPE_OPPORTUNITY_STATUS = 6;

export const DD_TYPE_INVESTOR_TYPE = 7;

export const DD_TYPE_BANK_TYPE = 8;

export const DD_TYPE_DP_NAMES = 15;

export const STATUS_SUCCESS = true;

export const SNACKBAR_ERROR = {variant: "error"};

export const SNACKBAR_SUCCESS = {variant: "success"};

export const STATUS_INACTIVE = 0;

export const CONTACT_TEAM_MESSAGE = "Our team will contact you soon."

export const MCA_TOKEN_LOGIN = 1;

export const DRAW_DOWN_TOKEN_LOGIN = 2;

export const NOMINATION_TOKEN=3;

export const FATCA_TOKEN=4;

export const STATUS_OPEN_OPPORTUNITY = 1502
export const STATUS_UPCOMING_OPPORTUNITY = 1501
export const STATUS_CLOSED_OPPORTUNITY = 1503

export const OTP_SENT_TO_MOBILE = 'MOBILE';
export const OTP_SENT_TO_EMAIL = 'EMAIL';
export const OTP_SENT_TO_BOTH = 'BOTH';
export const OTP_SENT_TO_NONE = 'NONE';

export const TRANSACTION_SUCCESS = "SUCCESS";
export const TRANSACTION_PENDING = "PENDING";
export const TRANSACTION_FAILED = "FAILED";
export const TRANSACTION_INITIATED = "INITIATED";

export const SIGN_STATUS_SUCCESS=1;
export const SIGN_STATUS_FAILED=0;
export const SIGN_STATUS_PENDING=2;

export const WORKYOGI_PARTNER = 27;

export const RECORD_DISPLAY_LIMIT = 20;