import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { showInterestInVote, getUserDetails } from '../apis/apis';
import { useSnackbar } from '../../context/SnackbarContext';
import {
  setDashboardTab,
  setUserAuth,
  setUserAuthToken,
  setVoteDetailLocalStorage,
  setVoteOptionLocalStorage,
  getVoteDetailLocalStorage
} from '../../utils/LocalStorageUtils';
import { setPartner_Id } from "../../utils/LocalStorageUtils"
import VotingDrawer from './voting/VotingDrawer';
import { getPartner_Id } from '../../utils/LocalStorageUtils';
import { fetchPartnerIdFromApi } from '../../utils/FetchLogo';

const Vote = () => {
  const ERROR = '-1';
  const [logo, setLogo] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVoteId, setSelectedVoteId] = useState(null);
  const [logoError, setLogoError] = useState(null);
  const [storedVote, setStoredVote] = useState(null);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [partnerId, setPartnerId] = useState(getPartner_Id());

  const history = useHistory();
  const { showSnackbar } = useSnackbar();
  const { id } = useParams();

  useEffect(() => {
    localStorage.clear();
    showInterestInVote(id)
      .then((res) => {
        if (res.status) {
          setIsLoading(false);
          setDashboardTab(4);
          setUserAuthToken(id);
          setUserAuth(true);
          setVoteDetailLocalStorage(res.data.voteDetail);
          setVoteOptionLocalStorage(res.data.voteOptionList);
          getUserDetails()
            .then((result) => {
              if (result.data.loginEnabled) {
                history.replace('/user/home/');
              }
              else {
                setOpen(true);
                const storedVotes = getVoteDetailLocalStorage();
                setStoredVote(getVoteDetailLocalStorage());
                if (storedVotes?.voteRid) {
                  setSelectedVoteId(storedVotes.voteRid);
                }
              }
            })
        } else {
          showSnackbar(res.description);
        }
      })
      .catch((e) => {
        showSnackbar(String(e));
      });
  }, [id, history, showSnackbar]);

  const handleClose = () => {
    setOpen(false);
    setDashboardTab(null);
    setUserAuthToken(null);
    setUserAuth(false);
    setVoteOptionLocalStorage(null);
    setShowSuccessDialog(true);
  }

  const fetchLogo = (partnerId) => {
    return `${process.env.REACT_APP_BASE_URL}/logo/${partnerId}`;
  };

  useEffect(() => {
    if (!partnerId) {
      fetchPartnerIdFromApi(
        setPartnerId,
        setPartner_Id,
        showSnackbar
      );
      setLogo(fetchLogo(partnerId));
    } else {
      setLogo(fetchLogo(partnerId));
      setLogoError(null);
    }
  }, [partnerId]);

  return isLoading ? (
    <div className="preview-loader-wrapper">
      <img className="logo" src={'/logo2.svg'} alt={'logo'} />
    </div>
  ) : (
    <div>
      <div className="d-flex align-items-center py-4 px-5 border-bottom">
        <div className="d-flex gap-5 align-items-center flex-grow-1">
          <img
            src={logo}
            alt={"logo"}
          />
        </div>
      </div>
      {showSuccessDialog && (
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-center">
          <div className="container mx-auto">
            <h2 className="fw-bold"> {(storedVote?.voteDetails?.voteStatus === "0" || storedVote?.voteDetails?.voteStatus === 0)
              ? "Your vote has been recorded successfully!"
              : "Your vote has been modified successfully!"}
            </h2>
          </div>
        </div>
      )}
      <VotingDrawer open={open} closeAction={handleClose} selectedVoteId={selectedVoteId} setCrossButton="false" />
    </div>

  );
};

export default Vote;