import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomSnackbar from "../../components/Toast/CustomSnackbar";
import { apiExternalPayment } from "../../apis/apis";

function MakePayment() {
    const { id } = useParams();
    const [configData, setConfigData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [toastStatus, setToastStatus] = useState("error");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [paymentFailed, setPaymentFailed] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [transactionCompleted, setTransactionCompleted] = useState(false);

    useEffect(() => {
        if (id) {
            validatePayment(id);
        }
    }, [id]);

    useEffect(() => {
        if (configData && Object.keys(configData).length > 0) {
            const config = {
                responseHandler: handlePaymentResponse,
                flowConfig: configData,
                flowType: "payments",
            };
            setIsLoading(false);
            window.loadBillDeskSdk(config);
        }
    }, [configData]);

    const validatePayment = (id) => {
        setIsLoading(true);
        if (id) {
            getTransactionDetails(id);
        } else {
            setErrorMessage("Transaction Not Completed");
            setToastStatus("error");
            setOpenSnackbar(true);
            setIsLoading(false);
        }
    };

    const getTransactionDetails = async (id) => {
        try {
            const res = await apiExternalPayment(id);
            setConfigData({
                merchantId: res.detail.merchantId,
                bdOrderId: res.detail.bdOrderId,
                authToken: res.detail.token,
                childWindow: true,
                returnUrl: "",
                crossButtonHandling: "Y",
                retryCount: 0,
            });
        } catch (err) {
            setIsLoading(false);
            setTransactionCompleted(true);
            setTimeout(() => setTransactionCompleted(false), 10000);
        }
    };

    const handlePaymentResponse = (response) => {
        if (response?.status === 200) {
            setPaymentSuccess(true);
            setTimeout(() => setPaymentSuccess(false), 10000);
        } else {
            setTimeout(() => setPaymentFailed(false), 10000);
            setPaymentFailed(true);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    if (paymentSuccess || paymentFailed) {
        return (
            <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-center">
                <div className="container mt-3 px-5 mx-auto">
                    <div className="my-5 py-5">
                        <h2 className="fw-bold">{paymentSuccess ? "Payment Successfully Completed" : "Payment Unsuccessful"}</h2>
                        <p className="fs-5">Transaction ID: {id}</p>
                        <p className="fs-6">Store this ID for future payment verification.</p>
                        {paymentSuccess && <p className="fs-6 text-success">Thank you for your investment.</p>}
                        {paymentFailed && <p className="fs-6 text-success">Try again for investment.</p>}
                    </div>
                </div>
            </div>
        );
    }

    if (transactionCompleted) {
        return (
            <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-center">
                <div className="container mt-3 px-5 mx-auto">
                    <div className="my-5 py-5">
                        <h2 className="fw-bold">Transaction Already Completed or Not Initiated</h2>
                        <p className="fw-semibold fs-5 mt-3">Transaction ID: {id}</p>
                        <p className="fw-semibold fs-6 mt-2">Store this ID for future payment verification.</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center text-center">
            {isLoading && <div className="fs-4">Loading...</div>}
            <CustomSnackbar
                open={openSnackbar}
                severity={toastStatus}
                duration={4000}
                text={errorMessage}
                handleClose={handleSnackbarClose}
            />
        </div>
    );
}

export default MakePayment;
